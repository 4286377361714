import React, { ReactNode } from 'react'

export default function ScreenWrapper({
  children,
  classNames,
}: {
  children: ReactNode
  classNames?: string
}) {
  return <main className={classNames}>{children}</main>
}
