import React from 'react'
import { MasterBtn } from 'atoms/buttons'
import SectionHeading from 'molecules/sectionHeading'
import one from 'assets/home/smartRide/battery.svg'
import two from 'assets/home/smartRide/seat.svg'
import three from 'assets/home/smartRide/tyre.svg'
import four from 'assets/home/smartRide/drive-line.svg'
import five from 'assets/home/smartRide/cost.webp'
import sideView from 'assets/home/smartRide/side-view.webp'

import { useNavigate } from 'react-router-dom'

const pointcard = [
  {
    title: '80-120 km range with detachable/swappable safe battery options',
    icon: one,
    alt: 'Range',
  },
  {
    title:
      'High Loading <br class="hidden md:block" /> space with  modular <br class="hidden md:block" />  rear seat',
    icon: two,
    alt: 'Space',
  },
  {
    title:
      'Long-life rugged <br class="hidden md:block" />  aggregates with <br class="hidden md:block" />  large 17-inch tires',
    icon: three,
    alt: 'Life',
  },
  {
    title:
      'Intelligent drive line to <br class="hidden md:block" />  adjust performance <br class="hidden md:block" />  with load ',
    icon: four,
    alt: 'Intelligence',
  },
  {
    title:
      'Best-in-class <br class="hidden md:block" />  total cost of <br class="hidden md:block" />  ownership',
    icon: five,
    alt: 'Cost',
  },
]

export default function SmartRide() {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/bolt')
  }

  return (
    <section className="blur-gradient relative">
      <div className="bg-black grid-lines bg-founders blade-top-padding-lg blade-bottom-padding-lg">
        <SectionHeading sectionTag="Xero Bolt" color="light" gradient />
        <div className="">
          <div className="pt-10 md:pt-12 w-full md:w-[70%] mx-auto px-4">
            <div className="grid rounded-xl place-content-center">
              <img
                src={sideView}
                alt="Side view of Xero Bolt"
                className="gsap-opacity-trans-appear rounded-xl"
              />
            </div>
          </div>
        </div>
        <div className="pt-10 md:pt-12">
          <h3
            className={`gsap-opacity-trans-appear w-11/12 2xl:w-9/12 mx-auto  font-semibold pt-1
          text-center leading-snug gradient-text`}
          >
            Your smart ride with a purpose
          </h3>
          <h6 className="gsap-opacity-trans-appear text-[#D0D0D0] text-center leading-snug font-medium pt-1 text-base sm:text-xl xl:text-2xl ">
            Safe. Tough. Profitable
          </h6>

          <div className="mt-12">
            <div className="gsap-stagger-parent flex-wrap flex flex-col md:flex-row items-center justify-center gap-x-5 gap-y-8">
              {pointcard.map((elem, index) => {
                const key = `${index}`
                return <FeatureCard key={key} data={elem} />
              })}
            </div>
          </div>
          <div className="gsap-opacity-trans-appear grid place-content-center mt-12">
            <MasterBtn
              aria-label="Know more"
              text="Know more"
              onClick={handleClick}
              type="button"
              size="base"
              className="px-10"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

type Feature = {
  title: string
  icon: string
  alt: string
}

function FeatureCard({
  data,
  className,
}: {
  data: Feature
  className?: string
}) {
  const { title, icon, alt } = data
  return (
    <article
      className={`gsap-stagger flex w-[300px] md:w-[200px] 2xl:w-[250px] flex-col gap-5 ${className}`}
    >
      <div className="grid place-content-center">
        <div className="w-[100px] h-[100px] xl:w-[120px] xl:h-[120px]  2xl:w-full 2xl:h-full ">
          <img className="select-none" src={icon} alt={alt} />
        </div>
      </div>
      <div className="w-full px-3 md:max-w-xs  mx-auto">
        <span
          className="text-white xl:pt-2 text-base block text-center font-regular"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
    </article>
  )
}
