import React from 'react'

import wheelSvg from '../assets/globals/wheel.svg'
import wheelSvgBlack from '../assets/globals/wheel_black.svg'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function SectionHeading({
  title,
  subtitle,
  sectionTag,
  gradient = false,
  showWheel = true,
  color = 'light',
}: {
  sectionTag?: string
  title?: string
  gradient: boolean
  color?: 'light' | 'dark'
  subtitle?: string
  showWheel?: boolean
}) {
  const headingClass = gradient
    ? ' gradient-text'
    : color === 'light'
    ? 'text-white'
    : 'text-[#2B2B2B]'

  const sunheadingClass =
    color === 'light' ? 'text-[#C6C6C6]' : 'text-[#4B4B4B]'

  const sectionTagClass = color === 'light' ? 'text-[#D0D0D0]' : 'text-black'

  return (
    <div className="wrapper">
      {showWheel && (
        <div className="w-4/12 mx-auto">
          {color === 'light' ? (
            <div className="grid place-content-center">
              <div
                className={`h-[40px] ${
                  sectionTag ? 'sm:h-[70px]' : 'sm:h-[60px]'
                }`}
              >
                <img
                  className="gsap-opacity-trans-appear w-[160px] h-[100px] sm:h-[160px]"
                  aria-hidden
                  src={wheelSvg}
                  alt="Wheel"
                />
              </div>
            </div>
          ) : (
            <div className="grid place-content-center">
              <div className="h-[60px]">
                <img
                  className="gsap-opacity-trans-appear w-[160px] h-[160px]"
                  src={wheelSvgBlack}
                  aria-hidden
                  alt="Wheel"
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div>
        {sectionTag && (
          <h4
            className={`gsap-opacity-trans-appear font-medium uppercase ${sectionTagClass} text-center`}
          >
            {sectionTag}
          </h4>
        )}
        {title && (
          <h3
            dangerouslySetInnerHTML={{ __html: title }}
            className={`gsap-opacity-trans-appear w-11/12 2xl:w-9/12 mx-auto font-semibold ${headingClass} ${
              sectionTag && 'pt-1'
            } text-center leading-snug`}
          />
        )}

        {subtitle && (
          <h6
            id="text-target-top"
            dangerouslySetInnerHTML={{ __html: subtitle }}
            className={`gsap-opacity-trans-appear font-medium pt-1 text-base sm:text-xl xl:text-2xl ${sunheadingClass} text-center leading-snug`}
          />
        )}
      </div>
    </div>
  )
}
