import { TextNavAnchor } from 'atoms/links'
import React, { ReactNode } from 'react'
import logo_with_text from 'assets/globals/xero_logo.svg'

export default function Footer() {
  return (
    <footer className="blade-top-padding bldae-bottom-padding bg-dark">
      <section className="flex flex-col  lg:flex-row px-3  md:w-11/12  max-w-screen-xl mx-auto   gap-y-6 lg:gap-10 blade-bottom-padding-sm">
        <div className="basis-0 grow shrink-[2] ">
          <h6 className="text-white mb-2 text-xl font-regular">Contact us</h6>
          <div>
            <span className="text-white text-opacity-70 text-lg font-regular">
              Email:
            </span>

            <a
              href="mailto:bharat@xeroev.co"
              rel="noreferrer"
              target="_blank"
              className="outline-darkGreen outline-offset-4 ml-2"
            >
              <span
                className="text-white text-opacity-90 hover:text-opacity-100 transition-all duration-300 ease-in-out font-regular 
            text-lg leading-tight font-medium tracking-wide"
              >
                bharat@xeroev.co
              </span>
            </a>
          </div>
          <div>
            <span className="text-white text-opacity-70 font-regular text-lg">
              Mobile:
            </span>
            <a
              href="tel:918527788288"
              rel="noreferrer"
              target="_blank"
              className="outline-darkGreen outline-offset-4 ml-2"
            >
              <span
                className="text-white text-opacity-90 hover:text-opacity-100 transition-all duration-300 ease-in-out font-regular 
            text-lg leading-tight font-medium tracking-wide"
              >
                +91-85277 88288
              </span>
            </a>
          </div>
        </div>
        <div className="lg:basis-32 grow shrink">
          <h6 className="text-white text-xl mb-2 font-regular">Address</h6>
          <div className="max-w-xl">
            <span className="font-regular text-white tracking-wide  text-lg text-opacity-70 leading-tight">
              Anantshree Vehicle Pvt. Ltd, 109 Ram Ratan{' '}
              <br className="xl:block hidden" /> Patel, Sec-1, Pithampur,
              Dist-Dhar, 454775
            </span>
          </div>
        </div>
        <div>
          <div className="flex-0 lg:basis-60 xl:basis-80 grow-0 shrink flex flex-col flex-nowrap  md:justify-center lg:justify-end gap-3 pl-1">
            <h6 className="text-white text-xl font-regular">
              Connect with us on
            </h6>
            <div className="flex flex-nowrap gap-4">
              <SocialProfile
                aria-label="Visit our Linkedin profile"
                href={process.env.REACT_APP_LINKEDIN ?? 'https://xeroev.co'}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="22"
                  width="20"
                  viewBox="0 0 448 512"
                  fill="inherit"
                >
                  <path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                </svg>
              </SocialProfile>

              <SocialProfile
                aria-label="Visit our Telegram profile"
                href={process.env.REACT_APP_TELEGRAM ?? 'https://xeroev.co'}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="24"
                  viewBox="0 0 20 17"
                  fill="inherit"
                  className="mr-1"
                >
                  <path
                    d="M1.94319 11.6749C6.33591 8.17547 9.26947 5.85838 10.7439 4.72359C14.9536 1.48354 15.8463 0.877443 16.4849 0.694088C16.6254 0.653908 16.9503 0.604332 17.1992 0.715419C17.4093 0.809218 17.5074 0.985675 17.5658 1.11236C17.6241 1.23905 17.7334 1.53394 17.7742 1.77631C18.2403 4.54268 18.8835 11.3867 19.1279 14.5594C19.2313 15.9019 18.8753 16.4568 18.4306 16.6209C17.4644 16.9775 16.5122 16.4332 15.3841 16.0613C13.6189 15.4794 12.6065 15.094 10.8917 14.524C8.90992 13.8654 9.83835 12.9628 10.5311 11.7086C10.7124 11.3803 14.0136 6.24037 14.0016 5.85756C14.0001 5.80968 13.9649 5.63914 13.8414 5.57721C13.7179 5.51528 13.5788 5.58505 13.479 5.63763C13.3376 5.71216 11.3637 7.9403 7.55746 12.3221C7.00188 12.972 6.44623 13.365 5.89049 13.5012C5.27784 13.6512 4.01736 13.6339 3.07045 13.5842C1.90901 13.5232 1.01069 13.5671 0.926588 12.9575C0.882785 12.6399 1.22165 12.2124 1.94319 11.6749Z"
                    fill="inherit"
                  />
                </svg>
              </SocialProfile>
              <SocialProfile
                aria-label="Visit our Twitter profile"
                href={process.env.REACT_APP_TWITTER ?? 'https://xeroev.co'}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="17"
                  viewBox="0 0 19 17"
                  fill="inherit"
                >
                  <path
                    d="M0.717527 0.398438L7.63758 9.42874L0.673828 16.7708H2.24109L8.33784 10.3427L13.2639 16.7708H18.5973L11.2879 7.23251L17.7697 0.398438H16.2024L10.5876 6.31854L6.05098 0.398438H0.717527ZM3.0223 1.52513H5.4725L16.2922 15.6439H13.842L3.0223 1.52513Z"
                    fill="inherit"
                  />
                </svg>
              </SocialProfile>
            </div>
          </div>
        </div>
      </section>
      <div className=" md:block hidden h-[1.5px] backdrop-blur-sm  w-full md:w-9/12 bg-divider-gradient w-container my-6 lg:my-2 "></div>

      <section className="grid place-content-center mt-4 md:mt-0  w-8/12  xl:w-3/12 mx-auto blade-top-padding-sm">
        <img
          src={logo_with_text}
          alt="Xero logo"
          className="w-full h-full object-contain object-center"
        />
      </section>

      <section>
        <section
          className="flex md:flex-row flex-col items-start mx-auto px-2 md:w-11/12 justify-center md:items-center 
        gap-y-4 md:gap-x-12 md:gap-y-10 blade-top-padding pb-5 md:pb-10 xl:max-w-5xl  "
        >
          <TextNavAnchor
            size="large"
            color="light"
            href="/"
            className="md:basis-44 md:text-center"
            text="Home"
          />
          <TextNavAnchor
            size="large"
            color="light"
            href="/bolt"
            className="md:basis-44 md:text-center"
            text="Bolt"
          />
          <TextNavAnchor
            size="large"
            color="light"
            href="/mink"
            className="md:basis-44 md:text-center"
            text="Mink"
          />
          <TextNavAnchor
            size="large"
            color="light"
            href="/#about-us"
            className="md:basis-44 md:text-center"
            text="About Us"
          />
          <TextNavAnchor
            size="large"
            color="light"
            href="/#contact-us"
            className="md:basis-44 md:text-center"
            text="Contact Us"
          />
        </section>
      </section>
      <div className=" md:block hidden h-[1.5px] backdrop-blur-sm w-full md:w-9/12 bg-divider-gradient w-container   my-4 lg:my-2  " />

      <section className="flex lg:flex-row flex-col lg:items-center  gap-6 lg:gap-4 justify-between   border-opacity-40  px-3  md:w-11/12  max-w-screen-xl mx-auto   py-8 lg:py-12">
        <div className="lg:hidden flex items-start gap-3 flex-col md:flex-row justify-start  md:pb-3  md:justify-center ">
          <TextNavAnchor
            size="large"
            color="light"
            href="/cookie-policy"
            text="Cookies"
          />
          <div>
            <TextNavAnchor
              size="large"
              color="light"
              href="/disclaimer"
              text="Disclaimar"
            />
          </div>
          <div>
            <TextNavAnchor
              size="large"
              color="light"
              href="/privacy-policy"
              text="Privacy Policy"
            />
          </div>
        </div>
        <div className="grow-0 shrink basis-96  lg:block hidden text-lg ">
          <h6 className="text-white text-opacity-70 lg:flex hidden font-regular  flex-nowrap items-center gap-1 ">
            @Xero. All rights reserved.
          </h6>
        </div>

        <div className="flex-1  items-center divide-x-1 divide-solid divide-white divide-opacity-40 lg:flex hidden justify-end">
          <div className="px-2">
            <TextNavAnchor
              size="large"
              color="light"
              href="/cookie-policy"
              text="Cookies"
            />
          </div>
          <div className="px-2">
            <TextNavAnchor
              size="large"
              color="light"
              href="/disclaimer"
              text="Disclaimar"
            />
          </div>
          <div className="px-2">
            <TextNavAnchor
              size="large"
              color="light"
              href="/privacy-policy"
              text="Privacy Policy"
            />
          </div>
        </div>

        <div className="">
          <h6 className="text-white lg:hidden flex  md:justify-center font-regular  flex-nowrap items-center gap-1  pl-2">
            @Xero. All rights reserved.
          </h6>
        </div>
      </section>
    </footer>
  )
}

function SocialProfile({
  href,
  children,
  ...rest
}: {
  href: string
  children: ReactNode
}) {
  return (
    <a
      {...rest}
      href={href}
      target="_blank"
      rel="noopner noreffer"
      className="rounded-full border-1 border-solid border-white hozver:border-green active:border-green  hover:bg-gradient-to-b from-white to-lightGreen 
       fill-white hover:fill-dark stroke-white outline-darkGreen outline-offset-4
       hover:stroke-dark  grid place-content-center  w-[46px] h-[46px]"
    >
      {children}
    </a>
  )
}
