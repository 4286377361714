import React from 'react'
import Banner from './01_banner'
import ZeroBoundries from './04_zero_boundries'
import FAQ from 'organisms/faq'
import { ChoosePreferences } from './choosePreferences'
import { Specifications } from './specifications'
import { Accessories } from './accessories'
import { RideWithConfidence } from './rideWithConfidence'
import InAction from './carousel'
import Carousal from '../home/carousals/carousel'
import bgImage from 'assets/mink/contact-form-02.webp'
import ContactUs from 'organisms/contactUs'
import useGSAP from 'hooks/useGSAP'

export default function PageMink() {
  const accordionData = [
    {
      title: 'How does XERO Mink compare to bicycles or electric scooters?',
      content: `XERO Mink offers a heavy and stable ride, similar to a regular bicycle but faster and lighter than a scooter. It efficiently covers local miles, especially in dense traffic. It's also a convenient option for senior citizens and school youth without riding licenses, ensuring quick travel without fatigue.
    `,
    },
    {
      title: 'Are drum brakes safer and more efficient on XERO Mink?',
      content: `Yes, XERO Mink boasts drum brakes at both the front and rear, providing safer braking performance similar to those used in scooters.
    `,
    },
    {
      title:
        'How do rugged and strong aggregates impact the total cost of ownership?',
      content: `XERO Mink's rugged components ensure longer vehicle life, enhancing reliability and uptime. This contributes to a better total cost of ownership by reducing maintenance costs.
    `,
    },
    {
      title: 'Can I choose a range option that suits my needs?',
      content: `XERO Mink offers a range of options to match your usage. Opting for higher range comes with additional weight, potentially affecting energy efficiency and tire life. Swappable and detachable options are also available for customization.
  `,
    },
    {
      title:
        'Is seating and riding comfort different from normal bikes or scooters?',
      content: `XERO Mink understands the importance of comfort for business commutes. With spacious seating and ergonomic design, it ensures rider comfort and efficient long-duration rides.
    `,
    },
    {
      title: 'What accessories can I get from XERO for customization?',
      content: `XERO Mink offers various accessories to tailor your vehicle for business needs, including fixed carriers, rear boxes for freezing or heating, and different seat options.
    `,
    },
    {
      title: 'Is XERO Mink suitable for carrying loads?',
      content: `XERO Mink can carry small loads, up to approximately 100 kg on flat terrain. It's well-suited for various load-carrying needs.
    `,
    },
    {
      title: "Can a standard crate fit in XERO Mink's front space?",
      content: `Yes, XERO Mink offers ample front and rear space, and the rear stand can be replaced with a small seat for added flexibility.
    `,
    },
    {
      title: 'What elements contribute to the Total Cost of Ownership (TCO)?',
      content: `XERO Mink's high TCO score is attributed to its reliability, durability, energy efficiency, loading space, loading capability, rider comfort, range options, and accessory compatibility. It's designed to offer competitive TCO to customers.
    `,
    },
  ]

  useGSAP('.bolt-master')

  return (
    <main className="bolt-master">
      <Banner />
      {/* <Carousal heading="Xero Mink in action" /> */}
      <InAction />
      <ChoosePreferences />
      <RideWithConfidence />
      <ZeroBoundries />
      <Specifications />
      <Accessories />
      <div className="specifications-wrapper">
        <FAQ accordionData={accordionData} bgClass="bg-lightSlate" />
      </div>
      <ContactUs bgImage={bgImage} />
    </main>
  )
}
