import React from 'react'
import ContactForm from './contact_form'

export default function ContactUs({ bgImage }: { bgImage: string }) {
  return (
    <section id="book-now">
      <section id="partner-with-us">
        <section id="book-test-ride">
          <section id="contact-us" className="bg-black">
            <ContactForm bgImage={bgImage} />
          </section>
        </section>
      </section>
    </section>
  )
}
