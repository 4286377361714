import React, { useLayoutEffect } from 'react'
// import logo from 'assets/globals/xero_logo.svg'
// import MailIcon from 'assets/coming_soon/mail_icon.svg'
// import PhoneIcon from 'assets/coming_soon/call_icon.svg'
import Header from 'organisms/header'
import Footer from 'organisms/footer'
import { MasterAnchor } from 'atoms/links'

export default function Error404() {
  useLayoutEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <main>
      <Header />
      <section className=" bg-slate-200 h-[85vh] grid place-content-center gap-1 text-black">
        <div className="grid place-content-center mx-auto">
          {/* <img
            src={logo}
            alt="Xero logo"
            className="h-40 lg:h-80 w-40 lg:w-80 absolut"
          /> */}
          <h1 className="font-bold text-7xl relative">Oops!</h1>
        </div>
        <div className="lg:mt-8">
          <h4 className="text-center font-regular">
            The page you were looking for <br /> does not exists
          </h4>
        </div>
        <div
          className="flex flex-col lg:flex-row items-center place-content-center gap-y-6 lg:grid-cols-2 gap-x-12 pt-8 
        md:pt-12"
        >
          <MasterAnchor
            className="px-6 xl:px-12 bg-lightGreen border-none !py-3 md:!py-[10px] w-fit"
            href="/"
            text="Go to home"
          />
          {/* <a
            className={`flex gap-x-2 items-center px-7 bg-darkGray tracking-wide rounded-full outline-none 
              border-[1.5px] border-white border-opacity-10 hover:border-opacity-60 transition-all duration-300 ease-in-out 
              border-solid hover:border-white text-white focus:border-opacity-60 font-regular   
            text-sm md:text-base xl:text-lg py-2 `}
            href="mailto:connect@xeroev.co"
          >
            <span>
              <img className="w-8 h-8 lg:w-10 lg:h-10" src={MailIcon} alt="" />
            </span>
            connect@xeroev.co
          </a>
          <a
            href="tel:8527788288"
            className={`flex gap-x-2 items-center justify-center bg-darkGray tracking-wide rounded-full 
            outline-none  border-[1.5px] 
            border-white border-opacity-10 hover:border-opacity-60 transition-all duration-300 ease-in-out border-solid  
            hover:border-white text-white focus:border-opacity-60 font-regular   
            text-sm md:text-base xl:text-lg py-2 px-8`}
          >
            <span>
              <img className="w-8 lg:w-10 h-8 lg:h-10" src={PhoneIcon} alt="" />
            </span>
            8527788288
          </a> */}
        </div>
      </section>
      <Footer />
    </main>
  )
}
