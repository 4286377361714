import React, { useLayoutEffect } from 'react'
import RenderMarkdown from 'organisms/markdown_renderer'

const content = `

The information, products, and services included in or available on Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) may include inaccuracies or typographical errors. Changes are periodically added to the information herein. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) and/or its respective suppliers/affiliates may make improvements and/or changes to the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website at any time.

There may be information, advertisements, and schemes displayed on this website from certain banks, financial institutions, non-banking financial companies, individual financiers, dealers, etc. To the fullest extent permissible, Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) disclaims the correctness, viability, availability, merchantability, or fitness of such information, advertisements, and schemes. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) hereby declares that the banks, financial institutions, non-banking financial companies, individual financiers, dealers, etc. are not its agents, partners, or principals, and Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) does not provide any guarantee or warranty or any representation on behalf of any of these banks, financial institutions, non-banking financial companies, individual financiers, dealers, etc.

Advice received via Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) should not be relied upon for personal, legal, commercial, or financial decisions, and you should consult an appropriate professional for specific advice tailored to your situation.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) and/or its respective suppliers, affiliates, associates, and representatives make no representations about the suitability, reliability, availability, timeliness, and accuracy of the information, products, and services contained on the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) site for any purpose. All such information, products, and services offered are provided on an "as-is, where-is" basis without a warranty of any kind. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) and/or its respective suppliers, associates, and affiliates hereby disclaim all warranties and conditions regarding the information, products, services, including all implied warranties and conditions of merchantability, fitness for a particular purpose, title, and non-infringement.

In no event shall Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) be liable for any direct, indirect, punitive, incidental, special, or consequential damages or any damages whatsoever, including without limitation, damages for loss of use arising out of or in any way connected with the use or performance of the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website, the delay or inability to use the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website or related services, the provision or failure to provide services, or for any information, products, services, and related graphics obtained through the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website, or otherwise arising out of the use of the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website, whether based on contract, tort, negligence, strict liability, or otherwise, even if Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) or any of its suppliers/affiliates has been advised of the possibility of damages. If you are dissatisfied with any portion of the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website or with any of these terms of use, your sole and exclusive remedy is to discontinue using the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) reserves the right to cancel any listing at any time for any reason, with or without cause/reason, and reserves the right to prohibit or restrict access to the services to anyone at any time, with or without cause/reason.

The material that appears on Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is for informational purposes only. Despite our efforts to provide useful and accurate information, errors may appear from time to time. Before purchasing goods or services you've read about on Anantshree Vehicles Pvt Ltd. (for its "Xero" brand), you should confirm any important information with our dealer that is crucial to your purchasing decision. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is not responsible for, and does not guarantee the performance of, any such goods or services.

If you rely on this service or any material available through this service, you do so at your own risk. You understand and accept that there may be delays, omissions, interruptions, inaccuracies, and/or other problems with the information, products, and services published on this site. This service is provided to you "as is." Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) and its affiliates, agents, and licensors cannot and do not warrant the accuracy, completeness, correctness, non-infringement, merchantability, or fitness for a particular purpose of the information available through the service (or any information, goods, or services that are referred to, advertised on, or sold through this service). Nor do they guarantee that this information will be error-free or continuously available, or that the service will be free of viruses or other harmful components. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) reserves the right to limit sales, including the right to prohibit sales to re-sellers. We are not responsible for typographical or photographic errors. You agree that any material, information, and ideas that you transmit to this site shall be and remain the property of Anantshree Vehicles Pvt Ltd. (for its "Xero" brand).

All transmissions shall be treated as non-confidential and non-proprietary, and Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) shall be under no obligation of any kind with respect to such information and shall be free to reproduce, use, disclose, and distribute the transmissions to others without limitation. Additionally, Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) shall be free to use any ideas, concepts, know-how, or techniques contained in such information for any purpose whatsoever, including but not limited to developing, manufacturing, or marketing products incorporating such information. You further agree that Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) may use information about your demographics and use of this site in any manner.

You agree and are hereby put on notice, that you are prohibited from posting or transmitting to or from this site any unlawful, threatening, libelous, defamatory, inflammatory, pornographic, or profane material or any other material that could give rise to any civil or criminal liability under the law. You are solely responsible for the content of any comments you make.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) may revise these terms of use by updating this posting. You agree that in the event any portion of these Site terms of use is found to be unenforceable, the remainder of these Site terms and conditions shall remain in full force and effect. By using this Site, you agree to be bound by any such revisions and should therefore periodically visit this page to determine the then-current terms of use to which you are bound.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website may collect information from website visitors that is not personally identifiable, including the visitor's operating system, browser, domain name, and the specific web pages on the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website that are accessed or visited. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) only collects this information in aggregate and only uses it to improve the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) informs website visitors that when they visit the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website, Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) may store some information on the visitor's computer in the form of a "cookie" or similar file. These files allow Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) to tailor a website to reflect a customer's listed or historical preferences. Most web browsers allow web users to exercise control over such files on their computers by erasing them, blocking them, or notifying the user when such a file is stored. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website visitors are asked to refer to their browser's instructions to learn about those functions.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is not responsible for any errors, omissions, or representations on any of our pages or on any links on any of our pages. We do not endorse in any way any advertisers on our web pages. Please verify the accuracy of all information on your own before relying on it. The linked sites are not under our control, and we are not responsible for the contents of any linked site or any link contained in a linked site, or any changes or updates to such sites. We provide these links to you only as a convenience, and the inclusion of any link does not imply endorsement by us of the site.

We hereby expressly disclaim any implied warranties imposed by the laws of any jurisdiction. We consider ourselves and intend to be subject to the jurisdiction only of the courts of Mumbai, Maharashtra, in India. If you disagree with any of our disclaimers above, please do not read the material on any of our pages. The Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein. Your use of Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) constitutes your agreement to all such terms, conditions, and notices.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) reserves the right to change the terms, conditions, and notices under which the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) Site is offered, including but not limited to the charges associated with the use of the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) site. You are responsible for regularly reviewing these terms and conditions.

The Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) site may contain bulletin board services, chat areas, news groups, forums, communities, personal web pages, calendars, and/or other message or communication facilities designed to enable you to communicate with the public at large or with a group (collectively, "Communication Services"). You agree to use the Communication Services only to post, send, and receive messages and material that are proper and related to the particular Communication Service. By way of example, and not as a limitation, you agree that when using a Communication Service, you will not:

* Defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights (such as rights of privacy and publicity) of others.
* Publish, post, upload, distribute, or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful topic, name, material, or information.
* Upload files that contain software or other material protected by intellectual property laws (or by rights of privacy or publicity) unless you own or control the rights thereto or have received all necessary consents. Upload files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of another computer.
* Advertise or offer to sell or buy any goods or services for any business purpose, unless such Communication Service specifically allows such messages. Conduct or forward surveys, contests, pyramid schemes, or chain letters.
* Download any file posted by another user of a Communication Service that you know, or reasonably should know, cannot be legally distributed in such a manner.
* Falsify or delete any author attributions, legal or other proper notices, proprietary designations, or labels of the origin or source of software or other material contained in a file that is uploaded.
* Restrict or inhibit any other user from using and enjoying the Communication Services.
* Violate any code of conduct or other guidelines which may be applicable to any particular Communication Service. Harvest or otherwise collect information about others, including e-mail addresses, without their consent. Violate any applicable laws or regulations.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) has no obligation to monitor the Communication Services. However, Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) reserves the right to review materials posted to a Communication Service and to remove any materials at its sole discretion. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) reserves the right to terminate your access to any or all of the Communication Services at any time without notice for any reason whatsoever.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) reserves the right at all times to disclose any information as necessary to satisfy any applicable law, regulation, legal process, or governmental request, or to edit, refuse to post, or to remove any information or materials, in whole or in part, in the sole discretion of Anantshree Vehicles Pvt Ltd. (for its "Xero" brand).

Always use caution when giving out any personally identifying information about yourself or your children in any Communication Service. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) does not control or endorse the content, messages, or information found in any Communication Service, and therefore, Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) specifically disclaims any liability with regard to the Communication Services and any actions resulting from your participation in any Communication Service.

Materials uploaded to a Communication Service may be subject to posted limitations on usage, reproduction, and/or dissemination. You are responsible for adhering to such limitations if you download the materials.

No compensation will be paid with respect to the use of your submissions, as provided herein. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is under no obligation to post or use any Submission you may provide and may remove any Submission at any time at the sole discretion of Anantshree Vehicles Pvt Ltd. (for its "Xero" brand).

By posting, uploading, inputting, providing, or submitting your Submission, you warrant and represent that you own or otherwise control all of the rights to your Submission as described in this section, including, without limitation, all the rights necessary for you to provide, post, upload, input, or submit the Submissions.

Software (if any) that is made available to download from the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) site, excluding software that may be made available by end-users through a Communication Service ("Software"), is the end work of the software provider. Your use of the Software is governed by the terms of the end-user license agreement, if any, which accompanies or is included with the Software ("License Agreement"). You may not install or use any Software that is accompanied by or includes a License Agreement unless you first agree to the License Agreement terms.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) reserves the right, in its sole discretion, to terminate your access to any or all of the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website and the related services or any portion thereof at any time, without notice. This agreement is governed by the laws of the Union of India. You hereby consent to the exclusive jurisdiction and venue of courts in Mumbai, Maharashtra, India, in all disputes arising out of or relating to the use of Anantshree Vehicles Pvt Ltd. (for its "Xero" brand). Use of Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including, without limitation, this paragraph.

You agree that no joint venture, partnership, employment, or agency relationship exists between you and Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) as a result of this agreement or use of the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) site. Performance of this agreement by Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is subject to existing laws and legal process, and nothing contained in this agreement is in derogation of the rights of Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) to comply with governmental, court, and law enforcement requests or requirements relating to your use of Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) or information provided to or gathered by Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) with respect to such use.

Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) with respect to the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) Site, and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between the user and Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) with respect to the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) site. A printed version of this agreement and any notice given in electronic form shall be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records originally generated and maintained in printed form.

The Invoice Prices generated by Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) are believed to be accurate, but Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) and its suppliers do not warrant or guarantee such accuracy. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) and its suppliers do not authorize the use of such prices for any purpose other than your personal use and prohibit to the maximum extent allowable the resale, redistribution, and use of this information for commercial purposes.

Each individual manufacturer, dealer, financier, and end-user participating at Anantshree Vehicles Pvt Ltd. (for its "Xero" brand)'s two-wheeler mall is responsible for setting and explaining their individual shipping, return, security, and other policies applicable to shopping in their stores and purchasing their products. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) does not guarantee, warrant, or endorse any product or service sold by a manufacturer, dealer, or financier in the two-wheeler mall, nor do we have any liability or responsibility for the quality or performance of any product or service sold to you by any manufacturer, dealer, or financier.

The purchase of any product or service from a manufacturer, dealer, or financier at Anantshree Vehicles Pvt Ltd. (for its "Xero" brand)'s two-wheeler mall is a transaction solely between you and that Merchant, and any question or dispute you may have regarding any such product or service should be addressed directly to the responsible Merchant. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) has no responsibility or liability for any Merchant or your relationship with such Merchant.

You are responsible for paying the entire amount due on your purchase, including any applicable taxes, shipping, and other charges assessed by the manufacturer, dealer, or financier. With respect to applicable taxes, the manufacturer, dealer, or financier will advise you subsequently of the exact amount of tax due on your purchase. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) has no responsibility whatsoever for any miscalculation or omission by a Merchant of applicable taxes on any sale. Used two-wheeler listing is offered solely as a forum for you to utilize the Services to buy and sell two-wheelers. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is not involved in any transaction between you and any other party who uses this Site and is not acting as your or any other party's agent.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) cannot screen, censor, or otherwise control the listings or any transactions, including whether a listing is accurately described, whether an item can be legally purchased or sold in your jurisdiction, or whether a transaction will be completed as described by the seller or the buyer. Furthermore, Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) cannot control or warrant the behavior of the users of the Services. As such, your use of this section is at your own risk, and Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) disclaims any liability with regard to the use of the Services and any actions resulting from your or any other party's participation in the Services. You hereby agree to release Anantshree Vehicles Pvt Ltd. (for its "Xero" brand), its suppliers, service partners, agents, employees, consultants, officers, and directors from all claims, demands, and damages, whether actual or consequential, of every kind, whether known or unknown, disclosed or undisclosed, arising out of or relating to any and all listings, transactions, or any other use of the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website.

Because Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is not involved in any transactions between you and other users of the Services, Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) cannot and will not be involved in resolving any disputes relating to any completed or uncompleted transaction for the purchase or sale of listed goods.

The Company is not responsible for any pornographic material, restricted secrets, or controlled content or material in any form, which may be uploaded by Registered Users/third parties onto the website. All submissions of this nature are unauthorized by the Company, and it shall use its best efforts to remove any such submission as soon as it is notified of the same. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is not responsible for and will not be liable for any conduct, failure to act, product, or services of Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) affiliated websites, including, without limitation, the manner and timing of opportunities to accumulate or use Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) and any malfunction, breach of warranty, or injury or damage suffered as a result of the products or services offered by any Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) affiliated website.

The Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) website may contain links to other websites. The Linked Sites are not under the control of Anantshree Vehicles Pvt Ltd. (for its "Xero" brand), and Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is not responsible for the contents of any Linked Site, including, without limitation, any link contained in a Linked Site, or any changes or updates to a Linked Site. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) of the site or any association with its operators.

Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) does not warrant that any website that it administers within the scope of the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) program or any linked website is free from viruses or other destructive materials, and the website and its affiliates shall not be liable for any damage caused to the Registered User's hardware or software as a result of the Registered User's participation in the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) program. Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) does not warrant that Registered User will be able to access any websites administered by Anantshree Vehicles Pvt Ltd. (for its "Xero" brand), and the Anantshree Vehicles Pvt Ltd. (for its "Xero" brand) will not be liable for any damages suffered by the Registered User as a result of not being able to access such websites.

`
export default function PrivacyPolicy() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="blade-top-padding-lg blade-bottom-padding-sm mb-4">
        <div className="blade-top-padding-sm blade-top-margin-sm">
          <h1 className="text-center  gradient-darkGreen-green  bg-clip-text text-transparent font-medium">
            Disclaimer
          </h1>
        </div>
      </div>
      <div
        className="markdown-wrapper text-black text-opacity-90 md:w-11/12 sm:px-5 px-3 max-w-4xl mx-auto 
        blade-bottom-padding-lg blade-bottom-margin-lg"
      >
        <RenderMarkdown content={content} />
      </div>
    </>
  )
}
