import React, { useState, useRef, useEffect } from 'react'
import gridImg from 'assets/home/about_grid.webp'
// import Reveal from 'molecules/reveal'
import { SwitchTransition, CSSTransition } from 'react-transition-group'

const data = ['Limits', 'Compromises', 'Emissions']
export default function About() {
  const [viewIndex, setViewIndex] = useState(0)
  const nodeRef = useRef<any>()

  useEffect(() => {
    const interval = setInterval(() => {
      setViewIndex((prev) => (prev + 1) % data.length)
    }, 2000)
    return () => clearInterval(interval)
  }, [])

  return (
    <section
      id="about-us"
      className="about-wrapper blade-top-padding-lg blade-bottom-padding-lg"
    >
      <div className="grid lg:grid-cols-2 gap-y-6 md:gap-y-12 grid-cols-1 w-container-sm">
        {/* <Reveal delay={100} className="flex flex-col justify-center"> */}
        <div className=" ">
          <h3 className="gsap-opacity-trans-appear font-semibold text-dark">
            Say Yes to{' '}
            <span className="text-[#71C20B] bg-clip-text text-transparent">
              XERO
            </span>
          </h3>

          <div className="gsap-opacity-trans-appear flex flex-nowrap gap-2 font-medium font-regular">
            <h4 className="font-medium">No</h4>
            <SwitchTransition>
              <CSSTransition
                key={viewIndex}
                nodeRef={nodeRef}
                timeout={500}
                classNames="textslide"
                unmountOnExit
              >
                <span className="list-none inline " ref={nodeRef}>
                  <h4 className="font-medium">{data[viewIndex]}</h4>
                </span>
              </CSSTransition>
            </SwitchTransition>
          </div>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="470"
            height="3"
            viewBox="0 0 651 2"
            fill="none"
            className="gsap-opacity-trans-appear w-11/12 max-w-lg mt-4"
          >
            <path
              d="M0 1.4751L651 1.47515"
              stroke="black"
              strokeWidth={1}
              strokeDasharray="4 4"
            />
          </svg>
          <h6 className="gsap-opacity-trans-appear text-darkGray font-medium leading-snug max-w-xl md:w-11/12 text-base md:text-lg xl:text-xl 2xl:text-2xl pt-5 xl:pt-7">
            At XERO, we are driven by a shared mission: to become a global brand
            committed to sustainability and electric transportation solutions
            for businesses. We offer best-in-class, environment friendly
            technology with no compromise on performance and affordability.
          </h6>
          <h6 className="gsap-opacity-trans-appear text-darkGray font-medium leading-snug max-w-xl md:w-11/12 text-base md:text-lg xl:text-xl 2xl:text-2xl pt-4 xl:pt-7 pr-2">
            Our value packed range of electric two-wheelers seamlessly blend
            performance, safety, durability and reliability. Leveraging the
            latest advancements in battery technology and electric propulsion
            systems, we aim to create a master breed of EVs that prioritize
            purpose and utility, and are proudly designed and manufactured in
            India!
          </h6>
        </div>
        {/* </Reveal> */}
        <div className="lg:pl-5 flex justify-center xl:flex-none">
          {/* <Reveal delay={100}> */}
          <img
            src={gridImg}
            alt="Features of Xero"
            className="gsap-scale rounded-xl h-full w-full object-contain object-center"
          />
          {/* </Reveal> */}
        </div>
      </div>
    </section>
  )
}
