import React, { useLayoutEffect } from 'react'
import RenderMarkdown from 'organisms/markdown_renderer'

const content = `

Our website utilizes cookies. You can learn more about cookies, how we utilize them, and how to manage them below.

By using this website, you consent to the use of cookies as outlined in this Cookie Notice. Specifically, you acknowledge the use of Performance/Analytics Cookies for the purposes explained below. If you do not consent to the use of these cookies, kindly disable them following the instructions provided in this Cookie Notice, preventing cookies from this website from being placed on your device.

###### **What is a Cookie?**

Cookies are small text files sent to your device when you visit a website. On subsequent visits, cookies are returned to the original website or another site recognizing that cookie. Cookies function as a website's memory, enabling it to recognize your device during return visits. They can also remember your preferences, enhance user experience, and customize the advertisements you see to align with your interests.

For more detailed information about cookies, including instructions on viewing and managing cookies set on your device, visit www.aboutcookies.org.

###### **Cookies Used on This Website**

We utilize both session cookies (active until you close your web browser) and persistent cookies (existing for a designated, longer period). The cookies used on this website generally fall into three categories: Strictly Necessary, Performance, and Functionality.

###### **Strictly Necessary**

Certain cookies on our website are essential for enabling you to navigate the site and utilize its features. These cookies do not gather data for marketing purposes or store information about your internet activity.

###### **Performance/Analytics Cookies**

Some of our website's cookies collect data on how visitors interact with the site, such as the most frequently visited pages and any encountered error messages. These cookies do not collect information that identifies visitors individually. Instead, the collected data is aggregated and therefore anonymous. It is used solely to enhance the functionality of our website.

Our website employs Google Analytics cookies. Information gathered by these cookies will be transmitted to and stored by Google on servers within the USA, adhering to its privacy practices. For additional insight into Google's privacy practices and their application to Google Analytics, please visit:



1. Google Analytics privacy overview
2. Opt out of Google Analytics tracking

###### **Functionality**

These cookies allow our website to remember the choices you make and provide enhanced, more personal features.

###### **Third-party cookies**

Our website also allows third parties to download cookies to your device. For example, we sometimes embed photos and video content from websites such as YouTube and Flickr. When you visit a page containing such content, you may be presented with cookies from these websites. You will also see embedded 'share' buttons on some of our web pages. These enable you to easily share content through a number of popular social networks. When you click on one of these buttons, a cookie may be set by the service you have chosen to share content through. These third party cookies are not under our control. For further information about their use, please visit the relevant third party website for further information.

###### **How to control and delete cookies?**

The majority of web browsers accept cookies, but you can usually change the web browser's settings to refuse new cookies, disable existing ones or simply let you know when new ones are sent to your device . In order to do this, follow the instructions provided by your browser. However, please be aware that, if you refuse or disable cookies, some of the website's functionality may be lost. In addition, disabling a cookie or category of cookie does not delete the cookie from your browser, you will need to do this yourself from within your browser.

###### **Changes to our use of cookies**

Any changes to our use of cookies for this website will be posted here and if necessary, signposted from our web pages highlighting any changes.

###### **Contact Information**

If you have any queries in relation to this notice, please contact us -

Anantshree Vehicles Pvt Ltd websites include Xero Bolt, Xero Mink, Xero Motoko and Xero “M” upcoming modular scooter 

`
export default function PrivacyPolicy() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="cookie-page-wrapper relative blade-bottom-padding-lg">
      <div className="blade-top-padding-lg blade-bottom-padding-sm mb-4  ">
        <div className="blade-top-padding-sm blade-top-margin-sm">
          <h1 className="text-center  gradient-darkGreen-green  bg-clip-text text-transparent font-semibold">
            Cookie Policy
          </h1>
        </div>
      </div>
      <div className="markdown-wrapper md:w-11/12 sm:px-5 px-3 max-w-4xl mx-auto blade-bottom-padding-lg ">
        <RenderMarkdown content={content} />
      </div>
    </section>
  )
}
