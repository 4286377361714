import React from 'react'
import { MasterBtn } from 'atoms/buttons'
import SectionHeading from 'molecules/sectionHeading'
import one from 'assets/home/throughTraffic/one.svg'
import two from 'assets/home/throughTraffic/two.svg'
import three from 'assets/home/throughTraffic/three.svg'
import four from 'assets/home/throughTraffic/four.svg'
import sideView from 'assets/home/throughTraffic/mink_image.webp'
import { useNavigate } from 'react-router-dom'
import useGsapStagger from 'hooks/useGsapStagger'

// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
// gsap.registerPlugin(ScrollTrigger)

const pointcard = [
  {
    title:
      'Internet-connected <br class="hidden md:block" /> smart and versatile <br class="hidden md:block" /> ride',
    icon: one,
    alt: 'Connectivity',
  },
  {
    title:
      'Detachable and swappable <br class="hidden md:block" /> battery options with a <br  class="hidden md:block" /> range of 50 - 120 km',
    icon: two,
    alt: 'Battery',
  },
  {
    title:
      'Agile lightweight chassis <br class="hidden md:block" /> with maximum speed <br  class="hidden md:block" /> of 25 kmph',
    icon: three,
    alt: 'Speed',
  },
  {
    title:
      'Heavy duty <br class="hidden md:block" /> suspension and <br class="hidden md:block" /> rugged aggregates',
    icon: four,
    alt: 'Suspension',
  },
]

export default function ThroughTraffic() {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/mink')
  }

  useGsapStagger(' .gsap-stagger-2')

  return (
    <section className="blur-gradient overflow-hidden relative">
      <div className="bg-black grid-lines bg-founders blade-top-padding-lg blade-bottom-padding-lg">
        <SectionHeading sectionTag="Xero Mink" color="light" gradient />
        <div className="">
          <div className="pt-10 md:pt-12 w-full md:w-[70%] mx-auto px-4">
            <div className="grid place-content-center ">
              <img
                src={sideView}
                alt="Side view of Xero Mink"
                className="gsap-opacity-trans-appear rounded-xl"
              />
            </div>
          </div>
        </div>
        <div className="pt-10 md:pt-12">
          <h3
            className={`gsap-opacity-trans-appear w-11/12 2xl:w-9/12 mx-auto  font-semibold pt-1
          text-center leading-snug gradient-text`}
          >
            Scooting through traffic
          </h3>
          <h6 className="gsap-opacity-trans-appear text-[#D0D0D0] text-center leading-snug font-medium pt-1 text-base sm:text-xl xl:text-2xl ">
            Smart. Agile. Versatile
          </h6>
          <div className="flex flex-col md:flex-row flex-wrap justify-center gap-x-5 gap-y-8 px-4 mt-12">
            <div className="gsap-stagger-parent-2 flex flex-col md:flex-row items-center justify-center gap-x-5 gap-y-8">
              {pointcard.map((elem, index) => {
                const key = `${index}`
                return <FeatureCard key={key} data={elem} />
              })}
            </div>
          </div>
          <div className="gsap-opacity-trans-appear grid place-content-center mt-12">
            <MasterBtn
              text="Know more"
              aria-label="Know more"
              onClick={handleClick}
              type="button"
              size="base"
              className="px-10"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

type Feature = {
  title: string
  icon: string
  alt: string
}

function FeatureCard({ data }: { data: Feature }) {
  const { title, icon, alt } = data
  return (
    <article className="gsap-stagger-2 flex w-[280px] md:w-[240px] 2xl:w-[250px] flex-col gap-5">
      <div className="grid place-content-center">
        <div className="w-[100px] h-[100px] xl:w-[120px] xl:h-[120px]  2xl:w-full 2xl:h-full ">
          <img className="select-none" src={icon} alt={alt} />
        </div>
      </div>
      <div className="w-full md:max-w-[600px] 2xl:max-w-[240px] mx-auto">
        <span
          className="text-white xl:pt-2 text-base block text-center font-regular"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
    </article>
  )
}
