import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react'
import { useRef, useState } from 'react'
import FullView from 'assets/mink/ride-with-confidence/full-view.webp'
import Image8 from 'assets/mink/ride-with-confidence/Front-suspension.webp'
import Image6 from 'assets/mink/ride-with-confidence/chasis.webp'
import Image7 from 'assets/mink/ride-with-confidence/drum-brakes.webp'
import Image1 from 'assets/mink/ride-with-confidence/ergonomic-design.webp'
import Image4 from 'assets/mink/ride-with-confidence/front-view.webp'
import Image5 from 'assets/mink/ride-with-confidence/headlights.webp'
import Image3 from 'assets/mink/ride-with-confidence/motor.webp'
import Image2 from 'assets/mink/ride-with-confidence/phone-on-off.webp'
import 'swiper/css'
import 'swiper/css/pagination'
import './index.css'

const data = [
  {
    id: 1,
    url: Image1,
    title: 'Ergonomic design for comfortable long ride hours',
  },
  { id: 2, url: Image2, title: 'On-off control through our mobile app' },
  { id: 3, url: Image3, title: 'Long life reliable motor and controller' },
  { id: 4, url: Image4, title: 'Metallic front guard and body' },
  { id: 5, url: Image5, title: 'High-efficiency LED headlights' },
  {
    id: 6,
    url: Image6,
    title: 'Rugged parts with a sturdy chassis weighing around 51 kg',
  },
  { id: 7, url: Image7, title: 'Drum brakes for enhanced braking performance' },
  {
    id: 8,
    url: Image8,
    title: 'Heavy duty front suspension for comfort and long product life ',
  },
]

const NumberButton = ({
  number,
  className,
  onClick,
}: {
  number: string
  className?: string
  onClick?: () => void
}) => {
  if (onClick) {
    return (
      <button
        aria-label={number}
        onClick={() => onClick()}
        className={`w-[40px] h-[40px] flex items-center justify-center rounded-full hover:!bg-lightGreen bg-lightGreen font-medium
        text-[12px] md:text-base xl:text-lg border-none outline-none transition-all duration-300 ease-in-out shadow-md cursor-pointer
        focus-visible:outline-darkGreen outline-offset-4
        ${className}`}
      >
        {number}
      </button>
    )
  } else {
    return (
      <span
        className={`w-[40px] h-[40px] flex items-center justify-center rounded-full bg-lightGreen font-medium text-base
        md:text-lg ${className}`}
      >
        {number}
      </span>
    )
  }
}

const Card = ({
  url,
  title,
  id,
  className,
}: {
  url: string
  title: string
  id: number
  className: string
}) => {
  return (
    <div
      className={`flex flex-col md:flex-row transition-opacity duration-700 ease-in-out opacity-10 
     w-full lg:w-[420px] xl:w-[460px] 2xl:w-[500px] h-[320px] md:h-[260px] lg:h-[200px] xl:h-[250px] 2xl:h-[300px] items-center ${className}`}
    >
      <div
        className="w-full md:w-[50%] ride-with-confidence-wrapper h-[54%] md:h-full rounded-tl-xl rounded-tr-xl md:rounded-tr-none 
      md:rounded-bl-xl"
      >
        <img
          className="w-full rounded-tr-xl md:rounded-tr-none rounded-tl-xl md:rounded-bl-xl h-full object-cover"
          src={url}
          alt={title}
        />
      </div>

      <div
        className="w-full md:w-[50%] md:py-6 px-4 md:px-8 h-full rounded-bl-xl rounded-br-xl md:rounded-tr-xl md:rounded-bl-none bg-black 
      flex flex-col justify-center"
      >
        <NumberButton
          className="w-[36px] h-[36px] xl:w-[40px] xl:h-[40px] text-lg lg:text-base xl:text-lg"
          number={`0${id}`}
        />
        <p className="font-medium text-base xl:text-lg mt-4 text-white">
          {title}
        </p>
      </div>
    </div>
  )
}

const NavigationButton = ({ swiperRef }: { swiperRef: any }) => {
  return (
    <div className="flex gap-x-6 md:gap-x-12 flex-row lg:flex-col gap-y-8 2xl:gap-y-12 items-center justify-center z-10 mt-4 lg:mt-0 lg:static  ">
      <div
        className="arrow-btn-gradient-dark-reverse w-[50px] h-[50px] lg:w-[40px] lg:h-[40px] xl:w-[45px] xl:h-[45px] 2xl:w-[60px] 2xl:h-[60px] 
      rounded-full flex items-center justify-center p-[2px]"
      >
        <button
          aria-label="Move to previous slide"
          onClick={() => swiperRef.current.swiper.slidePrev()}
          className="w-full h-full rounded-full hover:bg-black group bg-[#e4e4e4] lg:bg-[#c0c0c0] flex items-center justify-center 
          border-none focus-visible:outline-darkGreen outline-offset-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="currentColor"
            className="w-6 2xl:w-8 h-6 2xl:h-8 -rotate-90 lg:rotate-0 group-hover:stroke-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 15.75l7.5-7.5 7.5 7.5"
            />
          </svg>
        </button>
      </div>
      <div
        className="arrow-btn-gradient-dark w-[50px] h-[50px] lg:w-[40px] lg:h-[40px] xl:w-[45px] xl:h-[45px] 2xl:w-[60px] 2xl:h-[60px] 
      rounded-full flex items-center justify-center p-[2px]"
      >
        <button
          aria-label="Move to next slide"
          onClick={() => swiperRef.current.swiper.slideNext()}
          className="w-full h-full hover:bg-black group rounded-full bg-[#e4e4e4] lg:bg-[#c0c0c0] flex items-center justify-center 
          border-none focus-visible:outline-darkGreen outline-offset-4"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="currentColor"
            className="w-6 2xl:w-8 h-6 2xl:h-8 -rotate-90 lg:rotate-0 group-hover:stroke-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export const RideWithConfidence = () => {
  const [activeSlide, setActiveSlide] = useState(0)

  const swiperRef = useRef<SwiperRef>(null)
  const swiperRefMobile = useRef<SwiperRef>(null)

  return (
    <div
      className="flex gap-y-4 blade-top-padding-lg blade-bottom-padding-lg lg:p-0 flex-col-reverse lg:flex-row min-h-[800px] lg:h-[90vh] 
    ride-with-confidence-wrapper items-center"
    >
      <div className="lg:flex hidden w-[100%] bg-transparent lg:justify-end lg:w-[45%] xl:w-[45%] 2xl:w-[42%] h-full overflow-hidden relative">
        <div className="flex bg-transparent flex-col-reverse lg:flex-row gap-x-8 2xl:gap-x-12 lg:w-fit w-auto">
          {window.innerWidth > 1170 && (
            <NavigationButton swiperRef={swiperRef} />
          )}
          <Swiper
            className="!h-full cursor-grab lg:!w-fit"
            ref={swiperRef}
            initialSlide={0}
            centeredSlides
            onSlideChange={(slide) => {
              setActiveSlide(slide.activeIndex)
            }}
            direction="vertical"
            slidesPerView={3}
            spaceBetween={30}
            breakpoints={{
              1024: {
                slidesPerView: 3.6,
                spaceBetween: 30,
              },
              1280: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1336: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1536: {
                slidesPerView: 2.6,
                spaceBetween: 30,
              },
            }}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index} className="!h-auto w-full">
                <Card
                  className={activeSlide === index ? '!opacity-100' : ''}
                  title={item.title}
                  url={item.url}
                  id={item.id}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      {/* Mobile View  */}

      <Swiper
        className="!h-full cursor-grab !w-full lg:hidden px-4"
        ref={swiperRefMobile}
        initialSlide={0}
        centeredSlides
        onSlideChange={(slide) => {
          setActiveSlide(slide.activeIndex)
        }}
        slidesPerView={3}
        spaceBetween={30}
        breakpoints={{
          280: {
            slidesPerView: 1.3,
          },
          640: {
            slidesPerView: 1.5,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 1.4,
            spaceBetween: 30,
          },
        }}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index} className="!h-auto w-full self-stretch">
            <Card
              className={activeSlide === index ? '!opacity-100' : ''}
              title={item.title}
              url={item.url}
              id={item.id}
            />
          </SwiperSlide>
        ))}
        <NavigationButton swiperRef={swiperRefMobile} />
      </Swiper>

      <div
        className="h-full bike-wrapper flex flex-col items-center gap-y-12 2xl:gap-y-40 justify-center xl:justify-end w-[100%] 
         lg:w-[55%] xl:w-[55%] 2xl:w-[58%]"
      >
        <h3 className="gsap-opacity font-semibold">Ride with confidence</h3>
        <div className="gsap-opacity relative w-[76%] md:w-[70%] lg:w-auto h-[60%] lg:h-auto xl:h-auto 2xl:h-auto 2xl:-translate-y-20">
          {data.map((item, index) => (
            <NumberButton
              key={index}
              className={`absolute w-[20px] h-[20px] md:w-[32px] md:h-[30px] xl:w-[35px] xl:h-[35px] 2xl:w-[40px] 2xl:h-[40px] 
              top-0 z-10 numberButtons cursor-pointer ${
                activeSlide === index ? '!bg-lightGreen' : '!bg-white'
              }`}
              onClick={() => {
                if (
                  swiperRef &&
                  swiperRef?.current &&
                  window.innerWidth >= 1024
                ) {
                  swiperRef.current.swiper.slideTo(index)
                } else if (swiperRefMobile && swiperRefMobile?.current) {
                  swiperRefMobile.current.swiper.slideTo(index)
                }
              }}
              number={`0${item.id}`}
            />
          ))}
          <img
            className="h-full w-full 2xl:h-auto object-contain"
            src={FullView}
            alt="Full view of Xero Mink"
          />
        </div>
      </div>
    </div>
  )
}
