import React from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Pagination, Autoplay, Navigation } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { MasterAnchor } from 'atoms/links'
import { ArrowButton } from 'organisms/navigationButtons'

import bolt from 'assets/home/banner/bolt.webp'
import bolt_mobile from 'assets/home/banner/bolt_mobile.webp'
import mink from 'assets/home/banner/mink.webp'
import mink_mobile from 'assets/home/banner/mink_mobile.webp'
import scooter from 'assets/home/banner/scooter.webp'
import scooter_mobile from 'assets/home/banner/scooter_mobile.webp'

const minkSpecifications = [
  { id: 1, title: 'Smart' },
  { id: 2, title: 'Agile' },
  { id: 3, title: 'Versatile' },
]

const boltSpecifications = [
  { id: 1, title: 'Safe' },
  { id: 2, title: 'Tough' },
  { id: 3, title: 'Profitable' },
]

export default function Banner() {
  return (
    <section className="relative">
      <Swiper
        // autoplay={{
        //   delay: 5000,
        //   disableOnInteraction: false,
        //   pauseOnMouseEnter: true,
        // }}
        // loop
        preventInteractionOnTransition
        allowTouchMove={false}
        noSwiping
        speed={800}
        modules={[Autoplay, Pagination, Navigation]}
        pagination={{
          clickable: true,
        }}
        className="min-h-[600px] lg:min-h-[560px] bg-[#d0d0d0] xl:h-screen Homebanner-swiper cursor-gra"
      >
        <SwiperSlide className="h-full self-stretch w-full">
          <Bolt />
        </SwiperSlide>
        <SwiperSlide className="w-full h-full self-stretch">
          <Mink />
        </SwiperSlide>
        <SwiperSlide className="self-stretch h-full">
          <Scooter />
        </SwiperSlide>
        <div>
          <NavigationButton
            direction="left"
            aria-label="Move to previous slide"
            className="flex"
          />
          <NavigationButton
            direction="right"
            aria-label="Move to next slide"
            className="flex"
          />
        </div>
      </Swiper>
    </section>
  )
}

function NavigationButton({
  direction,
  className,
  ...rest
}: {
  direction: 'left' | 'right'
  className: string
}) {
  const swiper = useSwiper()

  const clickHandler = () => {
    if (direction === 'right') {
      swiper.slideNext()
    } else {
      swiper.slidePrev()
    }
  }

  return (
    <div
      className={`z-50 h-fit w-fit absolute top-[5%] md:top-[20%] xl:top-[18%] my-auto group bottom-0
    ${
      direction === 'right'
        ? 'right-[20px] md:right-[30px] lg:right-[12px] xl:right-[16px] 2xl:right-[20px]'
        : 'left-[20px] md:left-[30px] lg:left-[12px] xl:left-[16px] 2xl:left-[20px]'
    } ${className} `}
    >
      <ArrowButton
        direction={direction}
        arrowStyle="w-[23px] h-[23px] lg:w-6 lg:w-6"
        theme="light"
        onClick={clickHandler}
        {...rest}
        className="w-[40px] h-[40px] lg:!w-[40px] lg:!h-[40px] xl:h-[50px] xl:w-[50px]"
      />
    </div>
  )
}

const Bolt = () => {
  return (
    <section
      className="min-h-[600px] md:min-h-[760px] lg:min-h-[560px] flex flex-col items-center xl:h-screen bg-cover bg-bottom bg-no-repeat 
    overflow-hidden relative"
    >
      <div
        className="z-20 w-full mt-[150px] sm:mt-[100px] md:mt-[140px] lg:mt-[120px] xl:mt-[160px] 2xl:mt-[200px] px-12 md:px-0 
        sm:max-w-[600px] md:max-w-[650px] flex flex-col items-center md:items-start lg:max-w-[960px] lg:px-4 xl:max-w-[1200px] 
        2xl:max-w-[1480px] mx-auto"
      >
        <h3
          className=" gsap-opacity-trans-appear font-semibold text-[20px] md:text-[26px] xl:text-[32px] 2xl:text-[40px] leading-tight text-black max-w-xs 
        xl:max-w-[400px] 2xl:max-w-lg text-center md:text-left"
        >
          Experience excellence in electric mobility with XERO
        </h3>
        <p className=" gsap-opacity-trans-appear text-base md:text-xl lg:text-2xl mt-2 font-medium text-black">
          Go Green, Go Profitable
        </p>

        <div className="gsap-opacity-trans-appear mt-6 ">
          <MasterAnchor
            href="/bolt"
            size="large"
            className="bg-lightGreen px-6 md:px-8 !py-3 md:py-[14px] border-none text-black"
            text="Explore XERO Bolt"
          />
        </div>
      </div>

      <div className="absolute z-20 md:w-full bottom-10 lg:bottom-12 2xl:bottom-8">
        <div className="flex px-4 justify-center items-center gap-x-8 md:gap-x-0">
          {boltSpecifications.map((item, index) => (
            <React.Fragment key={index}>
              <p
                className="font-medium max-w-[200px] leading-5 text-center w-[30%] lg:w-full text-white 
             lg:opacity-80 md:text-lg 2xl:text-2xl"
              >
                {item.title}
              </p>
              <div
                className={`${
                  item.id === boltSpecifications.length && 'hidden'
                } w-[2px] bg-white opacity-60 h-5 md:h-[42px]`}
              />
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="absolute top-0 2xl:-bottom-28 left-auto h-full w-full">
        <img
          className="hidden lg:block w-full h-full xl:h-[108%] 2xl:h-full object-cover lg:object-right-[10%] 
          2xl:object-right"
          src={bolt}
          alt="Side and front view of Xero Bolt"
        />
        <img
          src={bolt_mobile}
          className="lg:hidden w-full h-full object-right-bottom object-cover"
          alt="Side and front view of Xero Bolt"
        />
      </div>
    </section>
  )
}

const Mink = () => {
  return (
    <section className="min-h-[600px] md:min-h-[760px] lg:min-h-[560px] bg-black  flex flex-col items-center xl:h-screen bg-cover bg-bottom bg-no-repeat overflow-hidden relative">
      <div className="absolute z-10 banner-gradient-nav w-full top-0 left-0 h-[40vh]" />

      <div
        className="z-40 bg-black bg-opacity-80 rounded-xl mt-[100px] sm:mt-[160px] md:mt-[160px] 2xl:mt-[200px] px-6 md:px-0 
        w-[80%] md:w-[50%] lg:max-w-[420px] xl:max-w-[500px] py-8 lg:py-12 flex flex-col items-center mx-auto"
      >
        <h3
          className="font-semibold w-full text-center text-[20px] md:text-[26px] xl:text-[28px] 2xl:text-[32px] leading-tight text-white 
        md:max-w-sm 2xl:max-w-md"
        >
          Experience excellence in electric mobility with XERO
        </h3>
        <p className="text-base md:text-xl lg:text-2xl mt-2 font-regular text-white">
          Go Green, Go Profitable
        </p>

        <div className="mt-6">
          <MasterAnchor
            href="/mink"
            size="large"
            className=" bg-lightGreen px-6 md:px-8 !py-3 md:py-[14px] border-none text-black"
            text="Explore XERO Mink"
          />
        </div>
      </div>

      <div className="absolute md:w-full z-20 bottom-10 lg:bottom-12 2xl:bottom-8">
        <div className="flex px-4 justify-center items-center gap-x-8 md:gap-x-0">
          {minkSpecifications.map((item, index) => (
            <React.Fragment key={index}>
              <p
                className="font-medium max-w-[200px] w-[30%] leading-5 text-center lg:w-full text-white 
           lg:opacity-80 md:text-lg 2xl:text-2xl"
              >
                {item.title}
              </p>
              <div
                className={`${
                  item.id === minkSpecifications.length && 'hidden'
                } w-[2px] bg-white opacity-60 h-5 md:h-[42px]`}
              />
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="absolute top-0 2xl:-bottom-28 left-auto h-full w-full">
        <img
          className="hidden lg:block w-full h-full object-cover lg:object-right-[10%]"
          src={mink}
          alt="Side and back view of Xero Mink"
        />
        <img
          src={mink_mobile}
          className="lg:hidden w-full h-full object-cover object-right-bottom"
          alt="Side and back view of Xero Mink"
        />
      </div>
    </section>
  )
}

const Scooter = () => {
  return (
    <section className="min-h-[600px] md:min-h-[760px] lg:min-h-[560px] flex flex-col items-center xl:h-screen bg-cover bg-bottom bg-no-repeat overflow-hidden relative">
      <div
        className="z-40 w-full mt-[100px] sm:mt-[120px] md:mt-[200px] 2xl:mt-[300px] px-12 md:px-0 sm:max-w-[600px] lg:max-w-[768px] 
  xl:max-w-[1000px] flex flex-col items-center sm:items-start mx-auto"
      >
        <h3 className="font-semibold text-[32px] md:text-[40px] xl:text-[52px] leading-tight text-black text-center sm:text-left  max-w-sm">
          The <br /> Modular <br /> Scooter
        </h3>
        <p className="text-[20px] md:text-[24px] xl:text-[26px] mt-2 font-medium text-black">
          COMING SOON
        </p>
      </div>

      <div className="absolute top-0 -bottom-20 2xl:-bottom-28 left-auto h-full w-full">
        <img
          className="hidden lg:block w-full h-full object-cover lg:object-right-[50%]"
          src={scooter}
          alt="Front look of the upcoming Xero scooter"
        />
        <img
          src={scooter_mobile}
          className="lg:hidden w-full h-full object-cover object-bottom"
          alt="Front look of the upcoming Xero scooter"
        />
      </div>
    </section>
  )
}
