import React from 'react'

const sizeMap = {
  small: 'text-sm',
  base: 'text-base',
  large: ' text-base md:text-lg',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}

type MasterBtnProps<T extends 'submit' | 'button'> = {
  text: string
  size: keyof typeof sizeMap
  type: T
  isDisabled?: boolean
  onClick?: T extends 'submit' ? never : () => void
  className?: string
  theme?: string
}

export function MasterBtn<T extends 'submit' | 'button'>({
  type,
  text,
  size,
  isDisabled,
  className,
  theme,
  ...rest
}: MasterBtnProps<T>) {
  const sizeClass: string = sizeMap[size]
  return (
    <button
      disabled={isDisabled}
      {...rest}
      type={type === 'button' ? 'button' : 'submit'}
      className={` bg-darkGray px-7 tracking-wide font-medium  rounded-md outline-darkGreen outline-offset-4 transition-all 
      duration-300 disabled:opacity-60 disabled:cursor-not-allowed ease-in-out text-white text-lg py-3
      ${
        theme === 'light'
          ? 'hover:bg-black hover:bg-opacity-95 focus:bg-black focus:bg-opacity-95'
          : 'border-[1.5px] border-solid  border-white border-opacity-10 hover:border-opacity-60 focus:border-opacity-60'
      } ${sizeClass} ${className}`}
    >
      {text}
    </button>
  )
}
