import React, { useState, useEffect, useRef } from 'react'
import bannerImgDesktop from 'assets/mink/banner.webp'
import bannerImgMobile from 'assets/mink/banner-mobile.webp'
import './index.css'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { MasterAnchor } from 'atoms/links'

const specifications = [
  { id: 1, title: 'Internet-connected smart and versatile ride' },
  { id: 2, title: 'Max speed up to 25 kmph' },
  { id: 3, title: '50 - 120 km detachable and swappable battery options' },
]

export default function Banner() {
  const [viewIndex, setViewIndex] = useState(0)
  const nodeRef = useRef<any>()
  useEffect(() => {
    const interval = setInterval(() => {
      setViewIndex((prev) => (prev + 1) % specifications.length)
    }, 3000)
    return () => clearInterval(interval)
  })

  return (
    <section
      className="min-h-[600px] md:min-h-[760px] lg:min-h-[560px] flex flex-col items-center xl:h-screen bg-cover bg-bottom bg-no-repeat overflow-hidden 
    relative bg-black"
    >
      <div
        className="gsap-opacity-trans-appear z-20 w-full mt-[100px] sm:mt-[160px] flex flex-col items-center sm:items-start md:mt-[175px] 2xl:mt-[310px] md:px-0 sm:max-w-[600px] lg:max-w-[768px] bg
      xl:max-w-[1200px] 2xl:max-w-[1300px] mx-auto"
      >
        <h3
          className="font-semibold text-[26px] md:text-[36px] xl:text-[40px] 2xl:text-[48px] leading-tight text-black sm:text-white max-w-md 
        xl:max-w-xl text-center md:text-left"
        >
          Scooting Through Traffic
        </h3>
        <p className="text-base md:text-xl 2xl:text-2xl  mt-2 font-medium text-black sm:text-white">
          Smart. Agile. Versatile
        </p>

        <div className="mt-6">
          <MasterAnchor
            href="#book-test-ride"
            size="large"
            className=" bg-lightGreen px-6 md:px-8 !py-3 md:py-[14px] border-none text-black"
            text="Test ride your Mink"
          />
        </div>
      </div>

      <div className="absolute md:w-full z-20 bottom-5 lg:bottom-5 xl:bottom-[9px] 2xl:bottom-5">
        <div className="md:flex px-4 items-center justify-center gap-x-2 md:gap-x-4 xl:gap-x-6 hidden">
          {specifications.map((item, index) => (
            <React.Fragment key={index}>
              <p
                className="font-medium max-w-[200px] md:max-w-[280px] leading-5 text-center w-[60%] lg:w-full text-white 
               lg:opacity-80 
              text-[14px] lg:text-lg"
              >
                {item.title}
              </p>
              <div
                className={`${
                  item.id === specifications.length && 'hidden'
                } w-[2px] bg-white opacity-60 h-[46px]`}
              />
            </React.Fragment>
          ))}
        </div>
        <div className="relative md:hidden">
          <SwitchTransition>
            <CSSTransition
              key={viewIndex}
              nodeRef={nodeRef}
              timeout={1000}
              classNames="mink-banner-text"
              unmountOnExit
            >
              <span className="list-none inline" ref={nodeRef}>
                <p className="font-medium max-w-[290px] text-center text-white text-[14px]">
                  {specifications[viewIndex].title}
                </p>
              </span>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>

      <div
        className="absolute top-0 -bottom-20 2xl:-bottom-28 
      left-auto h-full w-full"
      >
        <img
          className="hidden lg:block w-full h-full object-cover lg:object-right-[50%]"
          src={bannerImgDesktop}
          alt="Men riding on Xero Mink - Showing front view of Xero Mink"
        />
        <img
          src={bannerImgMobile}
          className="lg:hidden w-full h-full object-cover"
          alt="Men riding on Xero Mink - Showing front view of Xero Mink"
        />
      </div>
      <div className="absolute z-10 banner-gradient w-full top-0 left-0 h-full" />
    </section>
  )
}
