import React from 'react'

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import slideA from 'assets/bolt/carousel/slide-1.webp'
import slideB from 'assets/bolt/carousel/slide-2.webp'
import slideC from 'assets/bolt/carousel/slide-3.webp'
import slideD from 'assets/bolt/carousel/slide-4.webp'
import slideE from 'assets/bolt/carousel/slide-5.webp'
import slideF from 'assets/bolt/carousel/slide-6.webp'
import SectionHeading from 'molecules/sectionHeading'
import { ArrowButton } from 'organisms/navigationButtons'

export default function InAction() {
  return (
    <section className="blur-gradient relative overflow-hidden">
      <section className="bg-black grid-lines blade-bottom-padding-lg blade-top-padding-lg">
        <SectionHeading title="Own it as you like it" gradient />
        <div className="blade-top-padding-sm">
          <Carousel />
        </div>
      </section>
    </section>
  )
}

function Slide({ cover, desc }: { cover: string; desc?: string }) {
  return (
    <section className="gsap-scale h-full">
      <div className="h-full px-3">
        <img
          src={cover}
          className="w-full h-[20rem] md:h-[28rem] lg:h-[30rem] 2xl:h-[36rem] object-cover object-top rounded-2xl"
          alt={desc}
        />
      </div>
    </section>
  )
}

function Carousel() {
  return (
    <div className="cursor-grab relative bolt-carousel">
      <Swiper
        modules={[Pagination, Navigation, Autoplay]}
        initialSlide={1}
        slidesPerView={1}
        loop
        autoplay={{ delay: 5000 }}
        grabCursor
        centeredSlides
        slideToClickedSlide
        slideActiveClass="slideActiveClass"
        slideNextClass="slideNextClass"
        slidePrevClass="slidePrevClass"
        speed={800}
        breakpoints={{
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 1.4,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 1.6,
            spaceBetween: 30,
          },
          1536: {
            slidesPerView: 1.8,
            spaceBetween: 40,
          },
        }}
        className="relative px-4 md:px-0"
      >
        <SwiperSlide className="">
          <Slide
            cover={slideA}
            desc="A blue and green XERO Bolt motorcycle with a basket on the back, perfect for carrying small items."
          />
        </SwiperSlide>
        <SwiperSlide className="">
          <Slide
            cover={slideB}
            desc="A blue and green XERO Bolt motorcycle with water bottles attached to the back, ready for a long ride."
          />
        </SwiperSlide>
        <SwiperSlide className="">
          <Slide
            cover={slideC}
            desc="A blue and green XERO Bolt motorcycle with four cans attached to the back for storage purposes."
          />
        </SwiperSlide>
        <SwiperSlide className="">
          <Slide
            cover={slideD}
            desc="A blue and green XERO Bolt motorcycle with box attached to the back for delivery purposes."
          />
        </SwiperSlide>
        <SwiperSlide className="h-rem]">
          <Slide
            cover={slideE}
            desc="A blue and green XERO Bolt motorcycle with storage boxes attached at the back, ready for carrying items during travel."
          />
        </SwiperSlide>
        <SwiperSlide className="">
          <Slide
            cover={slideF}
            desc="A blue and green XERO Bolt motorcycle with a table and umbrella attached, providing a unique and convenient way for business."
          />
        </SwiperSlide>
        <div className="flex justify-center items-center mt-6 gap-x-6">
          <NavigationButton direction="left" className="flex" />
          <NavigationButton direction="right" className="flex" />
        </div>
      </Swiper>
    </div>
  )
}

function NavigationButton({
  direction,
  className,
}: {
  direction: 'left' | 'right'
  className: string
}) {
  const swiper = useSwiper()

  const clickHandler = () => {
    if (direction === 'right') {
      swiper.slideNext()
    } else {
      swiper.slidePrev()
    }
  }

  return (
    <div
      className={`z-10 h-fit w-fit md:absolute md:top-0 md:my-auto group md:bottom-0
    ${
      direction === 'right'
        ? ' md:right-[30px] lg:right-[50px] xl:right-[90px] 2xl:right-[8%]'
        : ' md:left-[30px] lg:left-[50px] xl:left-[90px] 2xl:left-[8%]'
    } ${className}`}
    >
      <ArrowButton direction={direction} theme="dark" onClick={clickHandler} />
    </div>
  )
}
