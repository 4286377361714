import React from 'react'
import useScrollToTop from './useScrollToTop'
import useGsapOpacity from './useGsapOpacity'
import useGsapOpacityTransAppear from './useGsapOpacityTransAppear'
import useGsapStagger from './useGsapStagger'
import useGsapScale from './useGsapScale'

const useGSAP = (pageClassName: string) => {
  useScrollToTop()
  useGsapOpacity(pageClassName + ' .gsap-opacity')
  useGsapOpacityTransAppear(pageClassName + ' .gsap-opacity-trans-appear')
  useGsapStagger(pageClassName + ' .gsap-stagger')
  useGsapScale(pageClassName + ' .gsap-scale')
}

export default useGSAP
