type ButtonProps = {
  theme: 'light' | 'dark'
  onClick: () => void
  disabled?: boolean
  className?: string
  direction: 'left' | 'right'
  arrowStyle?: string
}

export const ArrowButton = ({
  theme,
  onClick,
  disabled,
  className,
  direction,
  arrowStyle,
  ...rest
}: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      {...rest}
      className={`border-none bg-transparent group outline-none z-10 relative focus:border-none active:border-none 
        w-[50px] h-[50px] lg:w-[65px] lg:h-[65px] xl:w-[70px] xl:h-[70px] focus-visible:outline-darkGreen outline-offset-4 rounded-full
    ${className} ${disabled ? 'cursor-default' : 'cursor-pointer'}`}
    >
      {theme === 'light' ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 51 51"
          fill="none"
          className={`${
            disabled
              ? 'opacity-30'
              : 'group-hover:fill-black group-active:fill-black'
          }`}
        >
          <circle
            cx="25.6523"
            cy="25.5"
            r="23.9244"
            transform="rotate(-90 25.6523 25.5)"
            stroke="url(#paint0_linear_3181_1847)"
            strokeWidth="2.1511"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3181_1847"
              x1="25.6523"
              y1="0.500015"
              x2="35.2457"
              y2="36.744"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stopColor="#7E7D7D" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 71 72"
          fill="none"
          className={`${
            disabled
              ? 'opacity-30'
              : 'group-hover:fill-white group-active:fill-white'
          }`}
        >
          <circle
            cx="35.1967"
            cy="35.8451"
            r="34.1212"
            stroke="url(#paint0_linear_3181_1502)"
            strokeWidth="2.1511"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3181_1502"
              x1="35.1967"
              y1="0.648445"
              x2="48.7028"
              y2="51.6753"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="#7E7D7D" />
            </linearGradient>
          </defs>
        </svg>
      )}
      <span className="absolute w-fit h-fit left-0 right-0 mx-auto top-0 bottom-0 my-auto flex items-center justify-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="inherit"
          className={`w-6 h-6 lg:w-8 lg:h-8 stroke-[2.8px] ${
            direction === 'left' && 'rotate-180'
          } ${arrowStyle} ${
            disabled
              ? 'stroke-gray opacity-70'
              : theme === 'light'
              ? 'stroke-black group-hover:stroke-white'
              : 'stroke-white group-hover:stroke-black'
          } `}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 4.5l7.5 7.5-7.5 7.5"
          />
        </svg>
      </span>
    </button>
  )
}
