import { useCallback, useRef, useState } from 'react'
import SectionHeading from 'molecules/sectionHeading'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import './index.css'
import commercialUse from 'assets/mink/own_it/commercial.webp'
import personalUse from 'assets/mink/own_it/personal.webp'
import { ArrowButton } from 'organisms/navigationButtons'

const data = [
  {
    id: 1,
    url: commercialUse,
    title: 'Xero for Commercial use',
  },
  {
    id: 2,
    url: personalUse,
    title: 'Xero for personal use',
  },
]

const NavigationButton = ({ direction, className, disabled, ...rest }) => {
  const swiper = useSwiper()

  const clickHandler = () => {
    if (disabled) {
      return
    }
    if (direction === 'right') {
      swiper.slideNext()
    } else {
      swiper.slidePrev()
    }
  }

  return (
    <div
      className={`z-10 h-fit w-fit md:absolute md:top-0 md:my-auto group md:bottom-0
    ${
      direction === 'right'
        ? ' md:right-[30px] lg:right-[50px] xl:right-[90px] 2xl:right-[8%]'
        : ' md:left-[30px] lg:left-[50px] xl:left-[90px] 2xl:left-[8%]'
    } ${className}`}
    >
      <ArrowButton
        {...rest}
        direction={direction}
        theme="dark"
        onClick={clickHandler}
      />
    </div>
  )
}

export const ChoosePreferences = () => {
  const [preference, setPreference] = useState('commercial')
  const [activeSlide, setActiveSlide] = useState(1)
  const swiperRef = useRef(null)

  const HandlePreferenceChange = useCallback(
    (newPreference) => {
      setPreference(newPreference)

      if (swiperRef.current) {
        if (newPreference === 'commercial') {
          swiperRef.current.swiper.slidePrev()
        } else if (newPreference === 'personal') {
          swiperRef.current.swiper.slideNext()
        }
      } else {
        console.error(
          'Swiper instance is not available or slideTo method is not defined.',
        )
      }
    },
    [swiperRef],
  )

  return (
    <div className="blur-gradient overflow-hidden relative">
      <div className="blade-bottom-padding-lg blade-top-padding-lg bg-black">
        <SectionHeading title="Own it as you like it" gradient />
        <ul
          className="gsap-opacity-trans-appear px-8 flex gap-x-8 sm:gap-x-24 md:gap-x-32 xl:gap-x-44 items-center justify-center 
        mt-12 lg:mt-20 sm:list-disc z-20"
        >
          <li
            onClick={() => HandlePreferenceChange('commercial')}
            className={`text-white font-regular leading-5 text-center text-[18px] sm:text-[20px] lg:text-[28px] xl:text-[30px] cursor-pointer ${
              preference === 'commercial' ? 'opacity-100' : 'opacity-40'
            }`}
          >
            For commercial use
          </li>
          <li
            onClick={() => HandlePreferenceChange('personal')}
            className={`text-white font-regular leading-5 text-center text-[18px] sm:text-[20px] lg:text-[28px] xl:text-[30px] cursor-pointer ${
              preference === 'personal' ? 'opacity-100' : 'opacity-40'
            }`}
          >
            For personal use
          </li>
        </ul>
        <Swiper
          ref={swiperRef}
          className="gsap-opacity-trans-appear mt-6 cursor-grab sm:mt-8 md:mt-8 relative"
          spaceBetween={20}
          slidesPerView={1}
          speed={1500}
          onSlideChange={(slide) => {
            if (slide.activeIndex === 1) {
              setPreference('personal')
            } else {
              setPreference('commercial')
            }
            setActiveSlide(slide.activeIndex + 1)
          }}
          centeredSlides
        >
          {data.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                className="w-[92%] md:w-[75%] lg:w-[60%] 2xl:w-[70%] rounded-3xl mx-auto h-auto 
              sm:h-[400px] lg:h-[400px] 2xl:h-[680px]"
              >
                <img
                  className="w-full h-full object-cover rounded-3xl"
                  src={item.url}
                  alt={item.title}
                />
              </div>
            </SwiperSlide>
          ))}

          <div className="flex justify-center items-center mt-6 gap-x-6">
            <NavigationButton
              aria-label="Move to previous slide"
              direction="left"
              className="flex"
              disabled={activeSlide === 1}
            />
            <NavigationButton
              aria-label="Move to next slide"
              direction="right"
              className="flex"
              disabled={activeSlide === 2}
            />
          </div>
        </Swiper>
      </div>
    </div>
  )
}
