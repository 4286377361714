import React from 'react'
import ScreenWrapper from 'layouts/screen'
// import Marquee from 'molecules/marquee'
// import Partners from './partners'
import Banner from './01_banner'
import About from './02_about'
import Founders from './03_founders'
import Certifications from './04_certifications'
import Smartride from './smartRide'
import ThroughTraffic from './throughTraffic'
import MobileScreen from './mobileScreen'
import useGSAP from 'hooks/useGSAP'
import bgImage from 'assets/home/contact_form_bg.webp'
import ContactUs from 'organisms/contactUs'
import './index.css'

export default function HomePage() {
  useGSAP('.home-master')

  return (
    <ScreenWrapper classNames="home-master">
      <Banner />
      <About />
      <Smartride />
      <ThroughTraffic />
      <MobileScreen />
      <Founders />
      {/* <Partners /> confirm with aish before commenting out */}
      <Certifications />
      <ContactUs bgImage={bgImage} />
      {/* <Marquee text="Stay tuned for more...!" variant="light" /> */}
    </ScreenWrapper>
  )
}
