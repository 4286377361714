import React, { useMemo } from 'react'
import { ReactNode } from 'react-markdown/lib/ast-to-react'
import { Link, NavLink, useLocation } from 'react-router-dom'

const sizeMap = {
  small: 'text-sm',
  base: 'text-base',
  large: ' text-base md:text-lg',
  xl: ' text-base md:text-xl',
  xxl: ' text-xl xl:text-2xl',
  '3xl': ' text-xl md:text-2xl xl:text-3xl',
}

const colorMap = {
  dark: 'text-black',
  light: 'text-white',
  green: 'text-green',
}
type MasterAnchorProps = {
  size?: keyof typeof sizeMap
  text: string
  href: string
  target?: '_self' | '_blank'
  className?: string
}

export function TextAnchor({
  text,
  target,
  className = '',
  href,
  color = 'dark',
  size = 'base',
  ...rest
}: MasterAnchorProps & { color?: 'dark' | 'light' }) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  return (
    <NavLink target={target} to={href} {...rest}>
      {({ isActive }) => (
        <span
          className={`${
            isActive ? 'text-darkGreen' : `${colorClass}`
          } hover:text-darkGreen transition-colors duration-300 ease-in-out leading-tight px-2 
          font-regular tracking-wide  text-sm  md:text-base xl:text-lg ${className} ${sizeClass}  ${colorClass}`}
        >
          {text}
        </span>
      )}
    </NavLink>
  )
}

export function TextNavAnchor({
  text,
  target,
  href,
  color = 'dark',
  size = 'base',
  onNavigate,
  type,
}: MasterAnchorProps & {
  color?: 'dark' | 'light' | 'green'
  onNavigate?: () => void
  type?: string
}) {
  const sizeClass: string = sizeMap[size]
  const colorClass: string = colorMap[color]
  const location = useLocation()
  const hashURL = window.location.hash

  const isActive = useMemo(() => {
    if (hashURL.length) {
      return href.substring(2) === hashURL.substring(1)
    } else {
      return location.pathname === href
    }
  }, [href, hashURL, location.pathname])

  return (
    <>
      {type === 'anchor' ? (
        <a
          className={`${
            isActive ? 'text-darkGreen' : `${colorClass}`
          } font-medium hover:text-green tracking-wide text-sm  md:text-base 2xl:text-lg transition-colors duration-300 
          ease-in-out leading-tight px-2 cursor-pointer outline-darkGreen outline-offset-4 ${sizeClass}`}
          href={href}
        >
          {text}
        </a>
      ) : (
        <Link
          to={href}
          onClick={() => onNavigate?.()}
          className={`${
            isActive ? 'text-darkGreen' : `${colorClass}`
          } font-medium hover:text-green tracking-wide outline-darkGreen outline-offset-4 text-sm  md:text-base 2xl:text-lg transition-colors duration-300 ease-in-out leading-tight
            px-2 cursor-pointer ${sizeClass} `}
        >
          {text}
        </Link>
      )}
    </>
  )
}

export function MasterAnchor({
  text,
  size = 'base',
  href,
  target,
  className,
  onNavigate,
  ...rest
}: MasterAnchorProps & {
  onNavigate?: () => void
}) {
  const sizeClass: string = sizeMap[size]
  return (
    <Link
      to={href}
      target={target}
      onClick={() => onNavigate?.()}
      {...rest}
      className={`w-full text-center font-medium border-black hover:bg-black transition-all duration-300
      hover:text-white rounded-md border-2 px-6  pt-2 pb-2   ${sizeClass} ${className}`}
    >
      {text}
    </Link>
  )
}

export function Anchor({
  href,
  children,
  target = '_blank',
  onNavigate,
  ...rest
}: {
  href: string
  target?: '_self' | '_blank'
  children: ReactNode
  onNavigate?: () => void
}) {
  return (
    <Link
      {...rest}
      className="outline-green outline-offset-4"
      to={href}
      target={target}
      onClick={() => onNavigate?.()}
    >
      {children}
    </Link>
  )
}
