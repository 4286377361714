import React, { useEffect } from 'react'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)
ScrollTrigger.defaults({})

const useGsapStagger = (childClass: string) => {
  useEffect(() => {
    const ctx = gsap.context(() => {
      const elems = document.querySelectorAll(childClass)

      if (elems.length === 0) {
        return
      }

      gsap.from(elems, {
        opacity: 0,
        y: 50,
        stagger: 0.1,
        ease: 'ease',
        scrollTrigger: {
          trigger: elems[0],
          start: 'top 80%',
          toggleActions: 'play none none reverse',
        },
      })
    })

    return () => {
      ctx.revert()
    }
  }, [])
}

export default useGsapStagger
