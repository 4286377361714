import React, { useLayoutEffect } from 'react'
import RenderMarkdown from 'organisms/markdown_renderer'

const content = `

Your privacy is of paramount importance to us. We are committed to safeguarding your privacy and protecting your personal data that is with us. This Privacy Policy outlines the details of the personal data we collect and process, how we handle it, and the purposes for which we use it. Please read the following carefully to understand our practices regarding your personal data.

Throughout this document, the terms “we,” “us,” “our,” and “ours” refer to Xero EV (Anantshree Vehicles Pvt Ltd). The terms “you,” “your,” and “yours” refer to YOU (the individual whose personal data we are referring to).

######  **What Personal Data Do We Collect, Store, and Process?**

Categories of personal data that we collect, store, and process are as follows:



* Personal Identification Number (e.g., PAN Card No, Voter ID, GST no, Passport, and Aadhaar Number)
* Educational & Professional Data
* Financial Account Details (e.g., Bank account details)
* Demographic, Identity & Contact Data (e.g., name, last name, date of birth, gender, email address, address proof, contact number, language, occupation, physical address with pin code, preferences, and interests)
* Online Identifiers and other Technical Data (e.g., IP address, browser type, device identifiers)
* Personal Data collected via permissions on our mobile applications (e.g., camera contacts, location data, storage, photos, fingerprint/biometric, and SMS)
* Vehicle Data (e.g., registration number, registration type, registration data, and model type)
* Ride Data (e.g., range, lead, travel location, travel date, time, top speed, average speed, driver behavior data such as speed and braking habits)
* Telematics or similar app Data (e.g., data about speed, vehicle fall, and crash)
* Communication details (e.g., communication done through emails or other means)
* Generated Data (e.g., logs, transaction records)
* Information relevant to direct or indirect surveys that we undertake
* Testimonials that may contain some personal data

######  **Where Do We Collect Your Personal Data From?**

We collect your personal data in the following ways:



* When you visit our website or social media pages and fill in the registration form and use the Contact Us facility.
* When you use our mobile applications.
* When you interact with us via our websites or use services on our websites, including customer support.
* When you interact with our dealers or field teams.

######  **How Do We Use Your Personal Data?**

We use your personal data for the following purposes:



* If you express an interest in our services, we collect and share your personal data with our dealer, who, in turn, would contact you to schedule a store visit.
* If you are an existing customer, we collect your personal data for the following purposes:
* To authenticate your account or information on our applications that you use.
* To provide you with our products and services.
* To cross-sell vehicles on our app/website.
* To communicate with you regarding existing products and services availed by you, including notifications of any alerts or updates.
* To evaluate, develop, and improve our services.
* For market and product analysis and market and various research.
* To send you information about our other products or services which may be of interest to you.
* To obtain feedback and handle enquiries and complaints.
* To comply with legal or regulatory requirements.
* To reach out to you for service reminders.
* If you are a dealer, we collect your personal data for onboarding and invoicing purposes.
* If you are a website visitor, we process your personal data to optimize your website experience and customize content.

######  **Who Do We Share Your Personal Data With?**

We may disclose your personal data to:



* Our parent company for business and operational purposes.
* Our affiliates or group companies.
* Our dealer and/or third-party service providers who work for us or provide services or products to us.
* We may also share your personal data under the following circumstances:
* To respond to court orders, legal processes, or to establish our legal rights or defend against legal claims.
* If we are acquired by or merged with another company.

######  **International Data Transfer**

The data collected from you is stored in India. We may transfer your personal data to other countries outside your country of residence for any of the purposes defined in this Privacy Policy to the entities mentioned above. Any personal data that we transfer will be protected in accordance with this Privacy Policy.

######  **How Do We Secure Your Personal Data?**

We are committed to protecting your personal data in our custody. We take reasonable steps to ensure that appropriate physical, technical, and managerial safeguards are in place to protect your personal data from unauthorized access, alteration, transmission, and deletion. We train our employees about the importance of maintaining the privacy and security of your personal data. We ensure that the third parties with whom we share your personal data under appropriate contracts take appropriate security measures to protect your personal data in line with our policies.

######  **How Long Do We Keep Your Personal Data?**

We retain your personal data for as long as it is required to fulfill the purposes outlined in this Privacy Policy and for legal or regulatory reasons.

######  **How Do We Use Cookies and Other Tracking Mechanisms?**

We employ cookies and other tracking mechanisms on our website to gather data about you. The data collected through cookies and trackers is utilized to analyze trends and statistics. This analysis aids us in enhancing and personalizing your website experience, as well as providing improved website functionalities.

######  **Collection of Personal Data via Mobile Applications**

We gather Personal Data from you through Mobile Applications, utilizing permissions such as camera contacts/telephone, location, photo, SMS, etc. Your iOS and Android devices will notify you of the permissions our app requests and offer you the choice to consent to or decline the permission. The data obtained through these permissions is utilized to deliver the intended functionalities via the Mobile Applications.

######  **Links to Other Websites**

Our website may feature links to websites operated by other organizations. This Privacy Policy does not encompass the data processing practices of those organizations. We urge you to review the privacy policies of the other websites you visit.

######  **Maintenance of Policy**

We regularly assess and update our Privacy Policy to ensure its currency and accuracy. Any forthcoming changes to this Privacy Policy will be published on this page. The "last updated" date will be revised when changes are made.

######  **Limitation of Liability**

To the extent permissible by law, we shall not be held accountable for any indirect, incidental, special, consequential, or exemplary damages, including but not limited to damages for loss of profits, goodwill, data, information, or other intangible losses (even if we have been informed of the possibility of such damages), stemming from this Privacy Policy.

######  **Governing Law, Jurisdiction, and Dispute Resolution**

This Privacy Policy shall be construed and governed by the laws of India, without regard to principles of conflict of laws. For all disputes, differences, claims, controversies, and similar matters arising from or in connection with this Privacy Policy, including its interpretation, meaning, operation, or effect, the jurisdiction shall be Indore, Madhya Pradesh, India.

######  **Contact Information**

For any further queries or complaints related to privacy, you can contact our Data Protection Officer, Mr. Manish Kachhava, at the following address:

Email: [anantshreevehicles@gmail.com](mailto:anantshreevehicles@gmail.com) 

Business Address: Sec-I, Ram Ratan Patel Nagar, Pithampur-Dhar, Madhya Pradesh - 454775 Phone: +91 85117-73059

Last updated on August 2, 2023
`
export default function PrivacyPolicy() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="privacy-page-wrapper relative blade-bottom-padding-lg ">
      <div className="blade-top-padding-lg blade-bottom-padding-sm mb-4 ">
        <div className="blade-top-padding-sm blade-top-margin-sm">
          <h1 className="text-center  gradient-darkGreen-green  bg-clip-text text-transparent font-semibold">
            Privacy Policy
          </h1>
        </div>
      </div>
      <div
        className="markdown-wrapper text-black text-opacity-90 md:w-11/12 sm:px-5 px-3 max-w-4xl mx-auto 
      blade-bottom-padding-lg blade-bottom-margin-lg"
      >
        <RenderMarkdown content={content} />
      </div>
    </section>
  )
}
