import React from 'react'
import SectionHeading from 'molecules/sectionHeading'
import image from '../../assets/home/journey/xero-mobile.webp'
import imageMobileView from '../../assets/home/journey/mobile-screen-movile-view.webp'
// import Logo from '../../assets/globals/xero_logo.svg'

const data = [
  { id: 1, title: 'Define your Geographical area' },
  { id: 2, title: 'Monitor battery health and charging status' },
  { id: 3, title: 'Program and receive important alerts' },
  { id: 4, title: 'Real-time location tracking' },
]

export default function MobileScreen() {
  return (
    <section className="mobile-screen-wrapper items-center blade-top-padding-lg lg:blade-bottom-padding-lg">
      <SectionHeading
        title="Ease your journey with XERO app"
        subtitle="Track. Guide. Assist"
        showWheel={false}
        color="dark"
        gradient={false}
      />
      <div className="flex px-4 mt-2 md:mt-8 lg:mt-20 items-center justify-center">
        <div className="relative flex flex-col items-center justify-center">
          {data.map((item, index) => (
            <div
              key={index}
              className="gsap-opacity rounded-lg z-10 text-[14px] md:text-base font-medium hidden lg:flex items-center justify-center mobile-tabs 
            tabs-gradient border border-lightGreen py-3 text-center"
            >
              {item.title}
            </div>
          ))}

          <div className="z-10 flex mt-12 lg:hidden flex-col gap-y-4">
            <p className="gsap-opacity-trans-appear rounded-lg tabs-gradient text-[14px] px-2 text-center font-medium border border-lightGreen py-3">
              Real-time location tracking
            </p>

            <p className="gsap-opacity-trans-appear rounded-lg tabs-gradient text-[14px] px-2 text-center font-medium border border-lightGreen py-3">
              Define your Geographical area
            </p>

            <p className="gsap-opacity-trans-appear rounded-lg tabs-gradient text-[14px] px-2 text-center font-medium border border-lightGreen py-3">
              Program and receive important alerts
            </p>
            <p className="gsap-opacity-trans-appear rounded-lg tabs-gradient text-[14px] px-2 text-center font-medium border border-lightGreen py-3">
              Monitor battery health and charging status
            </p>
          </div>

          <img
            className="gsap-opacity w-full object-cover hidden lg:block relative md:mt-0 h-[600px] md:w-[300px] md:h-[540px] lg:w-full lg:h-full"
            alt="Guide for using Xero's mobile app"
            src={image}
          />
          <img
            src={imageMobileView}
            alt="Guide for using Xero's mobile app"
            className="gsap-opacity translate-y-[22px] lg:hidden"
          />
        </div>
      </div>
    </section>
  )
}
